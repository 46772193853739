<!-- 联系人管理模块 -->
<template>
  <a-row :gutter="[8, 8]">
    <a-col :span="12">
      <div class="divAreaCss">
        <a-card title="分组列表">
          <div class="tool">
            <div>
              <a-input-search
                v-model:value="keyword"
                placeholder="请输入关键词"
                enter-button
                @search="onSearch"
                @change.stop="onSearchChange"
              />
            </div>
            <div>
              <a-button
                type="primary"
                @click="handleAdd"
                style="margin-bottom: 8px"
                :disabled="addBtnDisabled"
              >
                <PlusOutlined />添加分组
              </a-button>
            </div>
          </div>
          <div>
            <a-table
              bordered
              :data-source="contactGroupList"
              :columns="main_columns"
              :pagination="false"
              :rowKey="(record) => record.groupId"
              :customRow="customRow"
            >
              <template
                v-for="col in ['groupName']"
                #[col]="{ text, record }"
                :key="col"
              >
                <div>
                  <a-input
                    v-if="editableData[record.groupId]"
                    v-model:value="editableData[record.groupId][col]"
                    style="margin: -5px 0"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template #operation="{ record }">
                <div>
                  <span v-if="editableData[record.groupId]">
                    <a-button
                      type="link"
                      @click.stop="handleSave(record.groupId)"
                      ><SaveOutlined />保存</a-button
                    >
                    <a-popconfirm
                      title="您确定要取消编辑吗?"
                      okText="确定"
                      cancelText="取消"
                      @confirm="handleCancel(record.groupId)"
                    >
                      <a-button type="link"><UndoOutlined />取消</a-button>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a-button
                      type="link"
                      @click.stop="handleEdit(record.groupId)"
                      ><EditOutlined />编辑</a-button
                    >
                    <a-popconfirm
                      title="您确信要执行删除吗?"
                      okText="确定"
                      cancelText="取消"
                      @confirm="handleDelete(record.groupId)"
                    >
                      <a-button type="link"><DeleteOutlined />删除</a-button>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </a-table>
            <div class="pagination">
              <a-pagination
                :current="pagination.pageIndex"
                :pageSize="pagination.pageSize"
                :total="pagination.total"
                :page-size-options="pagination.pageSizeOptions"
                :showTotal="(total) => `共 ${total} 条记录`"
                show-size-changer
                @change="onChangePage"
                @showSizeChange="onShowSizeChange"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </div>
          </div>
        </a-card>
      </div>
    </a-col>
    <a-col :span="12">
      <div class="divAreaCss">
        <a-card title="联系人列表">
          <div class="tool">
            <div>
              当前分组【<span style="color: red">{{ currentGroupName }}</span
              >】
            </div>
            <div>
              <a-button
                type="primary"
                @click="handleAdd1"
                style="margin-bottom: 8px"
                :disabled="addBtnDisabled1"
              >
                <PlusOutlined />添加联系人
              </a-button>
            </div>
          </div>
          <div>
            <a-table
              bordered
              :data-source="contactInfoList"
              :columns="sub_columns"
              :pagination="false"
              :rowKey="(record) => record.contactId"
            >
              <template
                v-for="col in ['contactName', 'contactPhone']"
                #[col]="{ text, record }"
                :key="col"
              >
                <div>
                  <a-input
                    v-if="editableData1[record.contactId]"
                    v-model:value="editableData1[record.contactId][col]"
                    style="margin: -5px 0"
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template #operation1="{ record }">
                <div>
                  <span v-if="editableData1[record.contactId]">
                    <a-button
                      type="link"
                      @click.stop="handleSave1(record.contactId)"
                      ><SaveOutlined />保存</a-button
                    >
                    <a-popconfirm
                      title="您确定要取消编辑吗?"
                      okText="确定"
                      cancelText="取消"
                      @confirm="handleCancel1(record.contactId)"
                    >
                      <a-button type="link"><UndoOutlined />取消</a-button>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a-button
                      type="link"
                      @click.stop="handleEdit1(record.contactId)"
                      ><EditOutlined />编辑</a-button
                    >
                    <a-popconfirm
                      title="您确信要执行删除吗?"
                      okText="确定"
                      cancelText="取消"
                      @confirm="handleDelete1(record.contactId)"
                    >
                      <a-button type="link"><DeleteOutlined />删除</a-button>
                    </a-popconfirm>
                  </span>
                </div>
              </template>
            </a-table>
            <div class="pagination">
              <a-pagination
                :current="pagination1.pageIndex"
                :pageSize="pagination1.pageSize"
                :total="pagination1.total"
                :page-size-options="pagination1.pageSizeOptions"
                :showTotal="(total) => `共 ${total} 条记录`"
                show-size-changer
                @change="onChangePage1"
                @showSizeChange="onShowSizeChange1"
              >
                <template #buildOptionText="props">
                  <span>{{ props.value }}条/页</span>
                </template>
              </a-pagination>
            </div>
          </div>
        </a-card>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { reactive, toRefs, onMounted, defineComponent } from "vue";
import {
  PlusOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined,
  UndoOutlined,
} from "@ant-design/icons-vue";
import { cloneDeep } from "lodash-es";
import { message } from "ant-design-vue";
import {
  getContactGroupListPage,
  getContactListPage,
  saveContactGroup,
  deleteContactGroup,
  saveContact,
  deleteContact,
} from "@/api/message/contact";
export default defineComponent({
  name: "Contact",
  components: {
    PlusOutlined,
    EditOutlined,
    SaveOutlined,
    DeleteOutlined,
    UndoOutlined,
  },
  setup() {
    const state = reactive({
      loading: false,
      loading1: false,
      addBtnDisabled: false,
      addBtnDisabled1: false,
      keyword: "",
      currentGroupName: "",
      main_columns: [
        {
          title: "分组名称",
          dataIndex: "groupName",
          width: "50%",
          slots: {
            customRender: "groupName",
          },
          sorter: (a, b) => {
            if (a.groupName < b.groupName) {
              return -1;
            }
            if (a.groupName > b.groupName) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: {
            customRender: "operation",
          },
        },
      ],
      sub_columns: [
        {
          title: "联系人姓名",
          dataIndex: "contactName",
          width: "30%",
          slots: {
            customRender: "contactName",
          },
          sorter: (a, b) => {
            if (a.contactName < b.contactName) {
              return -1;
            }
            if (a.contactName > b.contactName) {
              return 1;
            }
            return 0;
          },
        },
        {
          title: "联系人手机",
          dataIndex: "contactPhone",
          width: "30%",
          slots: {
            customRender: "contactPhone",
          },
        },
        {
          title: "操作",
          dataIndex: "operation1",
          slots: {
            customRender: "operation1",
          },
        },
      ],
      contactGroupList: [],
      contactInfoList: [],
      searchParam: {
        searchValue: "",
        accountId: JSON.parse(sessionStorage.getItem("userinfo")).id,
        pageIndex: 1,
        pageSize: 5,
      },
      searchParam1: {
        groupId: null,
        pageIndex: 1,
        pageSize: 5,
      },
      pagination: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 5, // 默认每页显示数量
        pageSizeOptions: ["5", "10", "20", "50"], // 每页数量选项
        total: 0, //总条数
      },
      pagination1: {
        pageIndex: 1, // 默认显示第1页
        pageSize: 5, // 默认每页显示数量
        pageSizeOptions: ["5", "10", "20", "50"], // 每页数量选项
        total: 0, //总条数
      },
    });
    onMounted(() => {
      initContactGroupList(state.searchParam);
    });
    const editableData = reactive({});
    const editableData1 = reactive({});
    const onSearch = () => {
      state.searchParam = {
        accountId: JSON.parse(sessionStorage.getItem("userinfo")).id,
        searchValue: state.keyword,
        pageIndex: 1,
        pageSize: 5,
      };
      initContactGroupList(state.searchParam);
    };
    const onSearchChange = () => {
      //state.keyword = e.data;
      state.searchParam.searchValue = state.keyword;
    };
    const customRow = (record) => {
      return {
        onDblclick: () => {
          state.searchParam1 = {
            groupId: record.groupId,
            pageIndex: 1,
            pageSize: 5,
          };
          state.currentGroupName = record.groupName;
          initContactList(state.searchParam1);
        },
      };
    };
    const onChangePage = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageIndex = page;
      state.searchParam.pageIndex = page;
      state.searchParam.pageSize = pageSize;
      initContactGroupList(state.searchParam);
    };
    const onShowSizeChange = (page, pageSize) => {
      if (state.keyword) {
        state.searchParam.searchValue = state.keyword;
      }
      state.pagination.pageSize = pageSize;
      state.pagination.pageIndex = 1;
      state.searchParam.pageIndex = 1;
      state.searchParam.pageSize = pageSize;
      initContactGroupList(state.searchParam);
    };
    const onChangePage1 = (page, pageSize) => {
      state.pagination1.pageIndex = page;
      state.searchParam1.pageIndex = page;
      state.searchParam1.pageSize = pageSize;
      initContactList(state.searchParam1);
    };
    const onShowSizeChange1 = (page, pageSize) => {
      state.pagination1.pageSize = pageSize;
      state.pagination1.pageIndex = 1;
      state.searchParam1.pageIndex = 1;
      state.searchParam1.pageSize = pageSize;
      initContactList(state.searchParam1);
    };

    const handleEdit = (key) => {
      state.addBtnDisabled = true;
      editableData[key] = cloneDeep(
        state.contactGroupList.filter((item) => key === item.groupId)[0]
      );
    };

    const handleEdit1 = (key) => {
      state.addBtnDisabled1 = true;
      editableData1[key] = cloneDeep(
        state.contactInfoList.filter((contact) => key === contact.contactId)[0]
      );
    };

    const handleAdd = () => {
      state.addBtnDisabled = true;
      const newData = {
        groupId: 9999,
        groupName: null,
      };
      state.contactGroupList.push(newData);
      editableData[9999] = cloneDeep(
        state.contactGroupList.filter((group) => group.groupId === 9999)[0]
      );
    };

    const handleAdd1 = () => {
      state.addBtnDisabled1 = true;
      const newData = {
        contactId: 9999,
        groupId: state.searchParam1.groupId,
        groupName: null,
        groupPhone: null,
      };
      state.contactInfoList.push(newData);
      editableData1[9999] = cloneDeep(
        state.contactInfoList.filter((contact) => contact.contactId === 9999)[0]
      );
    };

    const handleCancel = (key) => {
      state.addBtnDisabled = false;
      delete editableData[key];
      if (key === 9999) {
        state.contactGroupList = state.contactGroupList.filter(
          (group) => group.groupId != 9999
        );
      }
    };

    const handleCancel1 = (key) => {
      state.addBtnDisabled1 = false;
      delete editableData1[key];
      if (key === 9999) {
        state.contactInfoList = state.contactInfoList.filter(
          (contact) => contact.contactId != 9999
        );
      }
    };

    const handleSave = (key) => {
      if (editableData[key]["groupName"] == "") {
        message.error("分组名称不能为空！！！");
      } else {
        saveContactGroup(editableData[key]).then((resp) => {
          if (resp.code == 200) {
            Object.assign(
              state.contactGroupList.filter(
                (group) => key === group.groupId
              )[0],
              editableData[key]
            );
            if (state.keyword) {
              state.searchParam.searchValue = state.keyword;
            }
            if (key === 9999) {
              let total = state.pagination.total + 1;
              let lastPage = Math.ceil(total / state.pagination.pageSize);
              state.pagination.pageIndex = lastPage;
              state.searchParam.pageIndex = lastPage;
            }
            initContactGroupList(state.searchParam);
            state.addBtnDisabled = false;
            delete editableData[key];
          }
        });
      }
    };

    const validatePhoneNumber = (phone) => {
      const regex = /^1[3-9]\d{9}$/;
      return regex.test(phone);
    };

    const handleSave1 = (key) => {
      if (editableData1[key]["contactName"] == "") {
        message.error("联系人姓名不能为空！！！");
      } else if (editableData1[key]["contactPhone"] == null) {
        message.error("联系人手机不能为空！！！");
      } else if (
        validatePhoneNumber(editableData1[key]["contactPhone"]) == false
      ) {
        message.error("请输入正确的手机号码！！！");
      } else {
        saveContact(editableData1[key]).then((resp) => {
          if (resp.code == 200) {
            Object.assign(
              state.contactInfoList.filter(
                (contact) => key === contact.contactId
              )[0],
              editableData1[key]
            );
            let total = state.pagination1.total + 1;
            let lastPage = Math.ceil(total / state.pagination1.pageSize);
            state.pagination1.pageIndex = lastPage;
            state.searchParam1.pageIndex = lastPage;
            initContactList(state.searchParam1);
            state.addBtnDisabled1 = false;
            delete editableData1[key];
          }
        });
      }
    };

    const handleDelete = (key) => {
      deleteContactGroup({ id: key, logic: false }).then((resp) => {
        if (resp.code == 200) {
          let total = state.pagination.total - 1;
          let lastPage = Math.ceil(total / state.pagination.pageSize);
          if (lastPage < state.pagination.pageIndex) {
            state.pagination.pageIndex = lastPage;
            state.searchParam.pageIndex = lastPage;
          }
          initContactGroupList(state.searchParam);
        }
      });
    };

    const handleDelete1 = (key) => {
      deleteContact({ id: key, logic: false }).then((resp) => {
        if (resp.code == 200) {
          let total = state.pagination1.total - 1;
          let lastPage = Math.ceil(total / state.pagination1.pageSize);
          if (lastPage < state.pagination1.pageIndex) {
            state.pagination1.pageIndex = lastPage;
            state.searchParam1.pageIndex = lastPage;
          }
          initContactList(state.searchParam1);
        }
      });
    };

    const initContactGroupList = (searchParam) => {
      state.loading = true;
      getContactGroupListPage(searchParam).then((resp) => {
        if (resp) {
          state.contactGroupList = resp.obj.list;
          state.searchParam1.groupId = state.contactGroupList[0].groupId;
          state.currentGroupName = state.contactGroupList[0].groupName;
          initContactList(state.searchParam1);
          state.pagination.total = resp.obj.pagination.total;
          state.loading = false;
        }
      });
    };
    const initContactList = (searchParam) => {
      state.loading1 = true;
      getContactListPage(searchParam).then((resp) => {
        if (resp) {
          state.contactInfoList = resp.obj.list;
          state.pagination1.total = resp.obj.pagination.total;
          state.loading1 = false;
        }
      });
    };
    const refData = toRefs(state);
    return {
      ...refData,
      onSearch,
      onSearchChange,
      onChangePage,
      onShowSizeChange,
      onChangePage1,
      onShowSizeChange1,
      customRow,
      editableData,
      editableData1,
      handleAdd,
      handleAdd1,
      handleEdit,
      handleEdit1,
      handleSave,
      handleSave1,
      handleCancel,
      handleCancel1,
      handleDelete,
      handleDelete1,
      initContactGroupList,
      initContactList,
      validatePhoneNumber,
    };
  },
});
</script>
<style lang='scss' scoped>
.tool {
  display: flex;
  justify-content: space-between;
}
.divAreaCss {
  border: 1px solid #409eff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0px;
}
.pagination {
  margin: 6px 0px;
  padding: 6px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(183, 217, 241, 0.5);
}
.ant-card-head {
  height: 36px;
  padding: 0 10px;
}
.ant-card-head-wrapper {
  height: 36px;
}
.ant-card-body {
  padding: 4px;
}
.ant-card-head-title {
  height: 36px;
  color: #3805c4;
  padding: 6px;
}
.ant-table.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  padding: 6px 6px;
}
.ant-table.ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 6px 6px;
  background-color: rgba(183, 217, 241, 0.5);
}
</style>